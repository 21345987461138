import React from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  Close as CloseIcon,
  CloseSmall as CloseIconSmall,
} from '@wix/wix-ui-icons-common/on-stage';

import { Box } from '../Box';
import { Modal } from '../Modal';
import { Paper } from '../Paper';
import { Fade } from '../Fade';
import { IconButton } from '../IconButton';

import classes from './Dialog.scss';

export interface IDialogProps
  extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  bw?: boolean;
  fullscreen?: boolean;
  hideCloseButton?: boolean;
  scroll?: 'paper' | 'body';
  size?: 'sm' | 'md' | 'lg';

  paperProps?: React.ComponentProps<typeof Paper>;

  children?: React.ReactNode;
}

export function Dialog(props: IDialogProps) {
  const { bw, fullscreen, size, scroll, paperProps } = props;
  const backdropClick = React.useRef(false);
  const { t } = useTranslation();

  const { isMobile } = useEnvironment();

  return (
    <Modal focusTrap={!isMobile} onClick={handleBackdropClick} {...props}>
      <Fade appear in={props.isOpen} timeout={300}>
        <div
          data-hook="dialog-container"
          onMouseDown={handleMouseDown}
          className={cls(classes.container, {
            [classes.mobile]: isMobile,
            [classes.fullscreen]: fullscreen,
            [classes['scroll-paper']]: scroll === 'paper',
            [classes['scroll-body']]: scroll === 'body',
            [classes.small]: size === 'sm',
            [classes.medium]: size === 'md',
            [classes.large]: size === 'lg',
          })}
        >
          <Paper
            bw={bw}
            shadow={false}
            sideBorders={false}
            topBottomBorders={false}
            role="dialog"
            {...paperProps}
            className={cls(classes.paper, paperProps?.className)}
            data-hook="dialog-paper"
          >
            {!props.hideCloseButton && (
              <Box
                className={classes.close}
                style={{ background: 'rgba(255, 255, 255, 0.6)' }}
              >
                <IconButton
                  data-hook="close-icon-button"
                  onClick={handleCloseClick}
                  aria-label={t('groups-web.a11y.close')}
                  icon={isMobile ? <CloseIconSmall /> : <CloseIcon />}
                />
              </Box>
            )}

            {props.children}
          </Paper>
        </div>
      </Fade>
    </Modal>
  );

  function handleCloseClick(event: React.MouseEvent) {
    props.onClose(event);
  }

  function handleMouseDown(event: React.MouseEvent) {
    backdropClick.current = event.target === event.currentTarget;
  }

  function handleBackdropClick(event: React.MouseEvent) {
    if (!backdropClick.current) {
      return;
    }

    backdropClick.current = false;

    props.onClose(event);
  }
}

Dialog.displayName = 'wui/Dialog';
Dialog.defaultProps = {
  bw: true,
  scroll: 'paper',
  size: 'md',
  hideCloseButton: false,
};
